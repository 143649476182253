export const tr_messages = {
  n: "№",
  create: "Yaratmak",
  settings: "Ayarlar",
  complaint:"Şikayet",
  avance:"İlerlemek",
  report_card:"Tabelname",
  additional_work:"Ek iş",
  waiting:"Gözden geçiriliyor",
  received:"Kabul edildi",
  denied:"Reddedildi",
  approved:"Onaylandı",
  rejected:"Reddedildi",
  qty:"Miktar",
  vacation: "Tatil",
  my_requests: "İsteklerim",
  my_complaints: "Şikayetlerim",
  permission_ask: "İzin sormak",
  my_permission_requests: "İzin taleplerim",
  my_advance_requests: "Avans taleplerim",
  my_vacation_requests: "Tatil taleplerim",
  user: "Kullanıcı",
  reason: "Sebep",
  left_date: "Gitme zamanı",
  come_date: "Girme zamanı",
  status: "Durum",
  comment: "Yorum",
  request_data: "Talep tarihi",
  advance_amount: "Avans tutarı",
  back: "Geri",
  day: "Gün",
  hour: "Saat",
  minut:'Dakika',
  summa: "Söm",
  information_come_towork: "Çalışanın işe geldiği ve gelmediği bilgisi",
  today_working_hours: "Bugün çalışma saatleri",
  total_working_hours: "Toplam çalışma saatleri",
  total_working_days: "Toplam iş günü",
  time: "Zaman",
  data_time: "Tarih ve saat",
  from: "İlk tarih",
  to: "Son tarih",
  manager: "Yönetici",
  title: "konu",
  work_type: "Performans türü",
  count: "Sayı",
  confirm: "Tasdik etmek",
  whom_sent: "Kime gönderildi",
  message_text: "Mesaj metni",
  sent_time: "Gönderilen zaman",
  ask_avance:"Avans sormak",
  ask_vacation:"Tatil sormak",
  dedline:"Süre",
  send_complaint:"Şikayet gönder",
  details:'Detaylar',
  evaluation:"Değerlendirme",
  my_evaluation:"Verdiğim notlar",
  evaluat:"Seviye",
  send_evaluation:"Derecelendirmeyi kaydet",
  created_at: "Oluşturulma tarihi",
  updated_at: "Değiştirilme tarihi",
  name: "Ad",
  my_bonuses: "Bonuslarım",
  amount: "Miktar",
  my_penalties:"Cezalarım",
  user_information:"Profil bilgisi",
  user_profile:"Kullanıcı profili",
  limit_work_time:"Çalışma normu (saat)",
  actual_working_time:"Gerçek çalışma süresi (saat)",
  salary: "Maaş",
  bonuse: "Bonus",
  penalties:"Cezalar",
  requirement: "Gereksinim",
  sick_leave:'Больничные',
  save:'Сохранять',
  my_sick_leave_requests:'Больничные',
  company: "Şirket",
  branch_a: "Şube",
  department: "Bölüm",
  position: "Pozisyon",
  accept_vacation_managment:"Месяц отпуска",
  not_attached:"Не прикреплен"

}
