import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/element.js'
import '@/utils/library'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import VueApexCharts from 'vue-apexcharts'
import { i18n } from '@/utils/i18n';
import locale from 'element-ui/lib/locale'
const files = require.context('./components/', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
locale.use(lang)
import Inputmask from "inputmask";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, 
        faPersonWalkingArrowRight, 
        faSackDollar, 
        faUmbrellaBeach,
        faUserShield,
        faCalendarDays,
        faHammer,
        faFileLines,
        faFilePen,
        faBriefcase,
        faRankingStar,
        faStar,
        faHospitalUser
      } from '@fortawesome/free-solid-svg-icons'
      
library.add(faUserSecret, 
  faPersonWalkingArrowRight, 
  faSackDollar,
  faUmbrellaBeach,
  faUserShield,
  faCalendarDays,
  faHammer,
  faFileLines,
  faFilePen,
  faBriefcase,
  faRankingStar,
  faStar,
  faHospitalUser
  )

Vue.directive("mask", {
  bind: function (el, binding) {
    Inputmask(binding.value).mask(el.getElementsByTagName("INPUT")[0]);
  },
});

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(ElementUI, { locale })
Vue.config.productionTip = false
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
new Vue({
  router,
  store,  
  i18n,
  render: h => h(App)
}).$mount('#app')
