export const uz_messages = {
  n: "№",
  create: "Yaratish",
  settings: "Sozlamalar",
  complaint:"Shikoyat",
  avance:"Avans",
  report_card:"Tabel",
  additional_work:"Qo'shimcha ishlar",
  waiting:"Ko'rilmoqda",
  received:"Qabul qilindi",
  denied:"Rad etildi",
  approved:"Одобренный",
  rejected:"Отклоненный",
  qty:"Kол-во",
  vacation: "Taʼtil",
  my_requests: "Mening so’rovlarim",
  my_complaints: "Mening shikoyatlarim",
  permission_ask: "Izn so’rash",
  my_permission_requests: "Mening izn so’rovlarim",
  my_advance_requests: "Mening avans so’rovlarim",
  my_vacation_requests: "Mening taʼtil so’rovlarim",
  user: "Foydalanuvchi",
  reason: "Sabab",
  left_date: "Ketish vaqti",
  come_date: "Kelish vaqti",
  status: "Status",
  comment: "Izoh",
  request_data: "So’rov sanasi",
  advance_amount: "Avans miqdori",
  back: "Orqaga",
  day: "Kun",
  hour: "Soat",
  minut:'Daqiqa',
  summa: "so'm",
  information_come_towork : "Xodimning ishga kelgan va kelmaganligi haqida ma’lumot",
  today_working_hours: "Bugungi ish soatlari",
  total_working_hours: "Jami ish soatlari",
  total_working_days: "Jami ish kunlari",
  time: "Vaqt",
  data_time: "Sana va vaqt",
  from: "Dan",
  to: "Gacha",
  manager: "Rahbar",
  title: "Mavzu",
  work_type: "Ishlash turi",
  count: "Soni",
  confirm: "Tasdiqlash",
  whom_sent: "Kimga yuborilgan",
  message_text: "Xabar matni",
  sent_time: "Yuborilgan vaqt",
  ask_avance:"Avans so’rash",
  ask_vacation:"Taʼtil so’rash",
  dedline:"Muddat",
  send_complaint:"Shikoyatni jo’natish",
  details:'Tafsilotlar',
  evaluation:"Baholash",
  my_evaluation:"Men bergan baholar",
  evaluat:"Baho",
  send_evaluation:"Baholashni saqlash",
  created_at: "Yaratilgan sana",
  updated_at: "O'zgartirish sanasi",
  name: "Nomi",
  my_bonuses: "Bonuslarim",
  amount: "Miqdori",
  my_penalties:"Jarimalarim",
  user_information:"Profil malumotlari",
  user_profile:"Foydalanuvchi profile",
  limit_work_time:"Ish me'yori",
  actual_working_time:"Haqiqiy ish vaqti (soat)",
  salary: "Ish haqi",
  bonuse: "Bonus",
  penalties:"Jarima",
  requirement: "Talab",
  sick_leave:"Kasallik ta'tillar",
  save:'Saqlash',
  my_sick_leave_requests:"Kasallik ta'tillar",
  company: "Kompaniya",
  branch_a: "Filial",
  department: "Bo'lim",
  position: "Lavozim",
  accept_vacation_managment:"Месяц отпуска",
  not_attached:"Не прикреплен"

}
