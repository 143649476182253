import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/login',
    name: 'login',
    meta: {
      layout: 'empty'
    },
    component: () => import('@/views/login/index')
  }]

